<template>
  <el-upload
    name="logo"
    class="avatar-uploader"
    :action="imageUploadUrl"
    accept="image/x-png,image/jpeg"
    :headers="imageUploadHeaders"
    :on-success="handleAvatarSuccess"
    :on-error="handleAvatarError"
    :before-upload="beforeAvatarUpload"
    :show-file-list="false"
  >
    
    <!-- <img  :src="companyLogo(company)" class="w-75"> -->
    <div v-if="company.logo" class="image-card">
        <div class="image-border">
          <div class="image-content" :style="`background-image: url('${companyLogo(company)}');`">
          </div>
        </div>
    </div>
    <i v-else class="el-icon-plus avatar-uploader-icon" />
  </el-upload>
</template>

<script>
import Employer from '~/models/Employer'
import showsCompanyLogo from '~/mixins/showsCompanyLogo'

export default {
  mixins: [showsCompanyLogo],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      imageUploadUrl: Employer.baseURL('my-company/logo'),
      imageUploadHeaders: Employer.headers(),
    }
  },

  methods: {
    handleAvatarError (error) {
      let errors = JSON.parse(error.message).errors;
      Object.keys(errors).forEach(key => {
        errors[key].forEach(err => {
          this.$message.error(err)
        })
      })
    },

    handleAvatarSuccess (res) {
      this.company.logo = res.logo
      this.$store.commit('EMPLOYER_COMPANY_UPDATE_DATE_PROPS', res)
    },

    beforeAvatarUpload (file) {
      const isJPG = ['image/png', 'image/jpeg'].includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('The logo should be a png, jpg or gif.')
      }
      if (!isLt2M) {
        this.$message.error('The logo must be 2mb or less.')
      }
      return isJPG && isLt2M
    },
  },
}
</script>
