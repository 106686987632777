<template>
  <dashboard url-prefix="/employer">
    <div id="employer" class="accountdetails">
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane name="company-information" label="Company Information">
          <div v-if="company" class="detail-form company-information">
            <p class="mb-3">Update your company information</p>
            <el-form ref="frmUpdateCompany" :model="company" :rules="rules">
              <el-row :gutter="15">
                <el-col :xs="24" :md="8">
                  <p style="font-weight: normal; color: #333; margin-bottom: 10px;">Update your company logo</p>
                  <logo-uploader :company="company" class="company-profile" />
                </el-col>
                <el-col :xs="24" :md="16">
                  <el-row type="flex" justify="center">
                    <el-col :xs="24">
                      <el-form-item label="Company Name" prop="company_name">
                        <br/>
                        <p class="small custom-label">This can be your personal name if you are an entrepreneur</p>
                        <el-input v-model="company.company_name" :rows="3" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="center">
                    <el-col :xs="24">
                      <el-form-item label="Company URL" prop="company_url">
                        <el-input v-model="company.company_url" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center" class="mt-3">
                <el-col :xs="24">
                  <el-form-item prop="description">
                    <p style="font-weight: normal; color: #333; margin-top: 10px;margin-bottom: 10px;">Company Description</p>
                    <!-- <el-input type="textarea" v-model="company.description" :rows="3"></el-input> -->
                    <VueTrix v-model="company.description" input-id="bio" />
                    <span class="character-count" :class="{ 'text-danger': characterCount > 2000 }">{{ characterCount }}/2000</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <p class="mt-4 mb-2 orange-text bold"><i class="el-icon-office-building" /> Company address</p>
                  <el-form-item label="Address 1" prop="address_1">
                    <el-input v-if="company" v-model="company.address_1" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <el-form-item label="Address 2" prop="address_2">
                    <el-input v-model="company.address_2" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                  <el-form-item
                    label="Country" prop="country"
                  >
                    <el-select
                      v-model="company.country" filterable placeholder="Select" class="w-100"
                    >
                      <el-option
                        v-for="item in countries"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item v-if="company.country === 'US'" label="State" prop="state">
                    <el-select
                      v-model="company.state" filterable placeholder="Select" class="w-100"
                    >
                      <el-option
                        v-for="item in states"
                        :key="item.abbreviation"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="State" prop="state">
                    <el-input v-model="company.state" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label="Zip Code" prop="zip">
                    <el-input v-model="company.zip" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                  <el-form-item
                    label="City" prop="city"
                  >
                    <el-form-item>
                      <el-input v-model="company.city" />
                    </el-form-item>
                  </el-form-item>
                </el-col>
              </el-row>
              <error-alert :errors="Company.errors" />
              <div class="text-right buttons pb-4">
                <el-button :loading="Company.saving" :disabled="Company.saving" type="success" @click="save">
                  Save
                </el-button>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane name="billing-information" label="Billing Information">
        <div v-if="info.billing_information" class="detail-form billing-information">
          <p class="mb-4">Update your billing information</p>
          <el-alert v-show="success"
                    description="Billing information has been updated successfully."
                    type="success"
                    effect="dark"
                    :closable="true"
                    :show-icon="true">
          </el-alert>
          <el-alert v-show="error"
                    description="Something went wrong updating the billing information. Please check."
                    type="error"
                    :closable="true"
                    :show-icon="true"
                    effect="dark"
                    style="width: 85%; position: absolute; right: 87px; top: 0;">
          </el-alert>
            <el-form :label-position="labelPosition" :model="info.billing_information" :rules="rules" class="mt-4">
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <p class="mt-0 mb-3 orange-text bold "><i class="el-icon-office-building"></i> Billing address</p>
                  <el-checkbox class="mb-3">Same as Company Address</el-checkbox>
                  <el-form-item label="Address 1" prop="address_1">
                    <el-input v-model="info.billing_information.address_1"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <el-form-item label="Address 2" prop="address_2">
                    <el-input v-model="info.billing_information.address_2"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                  <el-form-item label="State" prop="state">
                    <el-input v-model="info.billing_information.state"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label="Zip Code" prop="zip">
                    <el-input v-model="info.billing_information.zip"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <el-form-item label="Country" prop="country">
                    <el-input v-model="info.billing_information.country"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
                </el-button>
              </div>
          </el-form>
        </div>
      </el-tab-pane> -->
      </el-tabs>
    </div>
  </dashboard>
</template>

<script>
import { EMPLOYER_UPDATE_SIGNUP_UPLOAD_LOGO, LOCAL_BASE_URL } from '../../vuex/utils/constant'
import Dashboard from '~/components/Dashboard/Dashboard'
import states from '~/data/us_states'
import VueTrix from 'vue-trix'
import { maxLength, required } from '~/rules'
import Employer from '~/models/Employer'
import ErrorAlert from '~/components/ErrorAlert.vue'
import LogoUploader from '~/components/Employer/LogoUploader'

export default {
  components: {
    Dashboard,
    VueTrix,
    ErrorAlert,
    LogoUploader,
  },

  data () {
    return {
      activeTab: 'company-information',
      url: LOCAL_BASE_URL,
      imageUpload: LOCAL_BASE_URL + EMPLOYER_UPDATE_SIGNUP_UPLOAD_LOGO,
      countries: require('~/data/countries').default,
      states,
      user: JSON.parse(localStorage.getItem('user')),
      imageUrl: '',
      rules: {
        address_1: [required('address 1'), maxLength('address 1')],
        address_2: [maxLength('address 2')],
        zip: [required('zip'),maxLength('zip code', 15)],
        state: [maxLength('zip code', 255)],
        company_name: [required('company_name'),maxLength('company_name', 255)],
        // company_url: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (value !== '') {
        //         const url = new RegExp('^(https:\\/\\/)' + // protocol
        //               '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        //               '((\\d{1,3}\\.){3}\\d{1,3}))|' + // OR ip (v4) address
        //               'localhost' + // OR localhost
        //               '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        //               '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        //               '(\\#[-a-z\\d_]*)?$', 'i')
        //         if (!url.test(value)) {
        //           callback(new Error('The company URL field must be valid'))
        //         }
        //         if (value.length >= 255) {
        //           callback(new Error('The company URL field must have a maximum 255 characters'))
        //         }
        //       }
        //       return callback()
        //     },
        //     trigger: 'blur',
        //   },
        // ],
        country: [required('country'),maxLength('country', 100)],
      },
      Company: new Employer('my-company'),
    }
  },

  computed: {
    company () {
      return this.Company.data
    },

    characterCount () {
      if (! this.company.description) {
        return 0
      }

      return this.stripHtmlTags(this.company.description).length
    },
    errors () {
      const errors = this.Company.errors
      if (
        errors['company.description'] &&
          errors['company.description'].length
      ) {
        return errors['company.description'][0].replace('company.description', 'company description')
      }
      if (errors['company.company_url'] && errors['company.company_url'].length) {
        return errors['company.company_url'][0].replace('company.company', 'company ')
      }
      if (Object.keys(errors).length) {
        return 'Something went wrong updating the company information. Please check.'
      }
      return false
    },
  },

  watch: {
    '$route.hash': function (newValue) {
      this.setActivePage(newValue)
    },
  },

  mounted () {
    const hash = this.$route.hash
    if (hash) {
      this.setActivePage(hash)
    }
  },

  methods: {
    setActivePage (page) {
      this.activeTab = page.replace('#', '')
    },

    save () {
      this.Company.save().then(s => {
        this.$store.commit('EMPLOYER_COMPANY_UPDATE_DATE_PROPS', s.data)
      })
    },

    stripHtmlTags (str) {
      if ((str === null) || (str === '')) { return false } else { str = str.toString() }
      return str.replace(/<[^>]*>/g, '')
    },
  },
}
</script>

<style lang="scss">
.el-form-item__content .custom-label {
  color: #333;
  font-weight: normal;
}
#employer.accountdetails .company-information, #employer.accountdetails .billing-information {
  .el-form-item__label {
    margin-bottom: 0;
  }
  .el-form-item {
    margin-bottom: 14px;
  }
}
#employer.accountdetails {
  .avatar-uploader {
    text-align: left;
  }
  .avatar-uploader-icon {
    width: 165px;
    height: 165px;
    line-height: 165px;
  }
}
</style>
